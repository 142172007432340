import React from 'react'
import './styles.css';
import { Link } from 'react-router-dom';


function Navbar()
{

    return <>
    
  {/* <div>
  {/* Offcanvas Area Start */}
  <div className="fix-area">
    <div className="offcanvas__info">
      <div className="offcanvas__wrapper">
        <div className="offcanvas__content">
          <div className="offcanvas__top mb-5 d-flex justify-content-between align-items-center">
            <div className="offcanvas__logo">
              

                <img src="assets/img/logo/inam.jpg" alt="logo-img" style={{width:'65px'}}/>
                
            </div>
            <div className="offcanvas__close">
              <button>
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
          <p className="text d-none d-xl-block">
            Nullam dignissim, ante scelerisque the  is euismod fermentum odio sem semper the is erat, a feugiat leo urna eget eros. Duis Aenean a imperdiet risus.
          </p>
          <div className="mobile-menu fix mb-3" />
          <div className="offcanvas__contact">
            <h4>Contact Info</h4>
            <ul>
              <li className="d-flex align-items-center">
                <div className="offcanvas__contact-icon">
                  <i className="fal fa-map-marker-alt" />
                </div>
                <div className="offcanvas__contact-text">
                  <a target="_blank" href="#">DurgaNagar,Hydrebad</a>
                </div>
              </li>
              <li className="d-flex align-items-center">
                <div className="offcanvas__contact-icon mr-15">
                  <i className="fal fa-envelope" />
                </div>
                <div className="offcanvas__contact-text">
                  <a href="mailto:info@example.com"><span className="mailto:info@example.com">info@inamsolutions.com</span></a>
                </div>
              </li>
              <li className="d-flex align-items-center">
                <div className="offcanvas__contact-icon mr-15">
                  <i className="fal fa-clock" />
                </div>
                <div className="offcanvas__contact-text">
                  <a target="_blank" href="#">Mon-friday, 09am -05pm</a>
                </div>
              </li>
              <li className="d-flex align-items-center">
                <div className="offcanvas__contact-icon mr-15">
                  <i className="far fa-phone" />
                </div>
                <div className="offcanvas__contact-text">
                  <a href="tel:+91 9390282096">+ 91(903050)-3222</a>
                </div>
              </li>
            </ul>
            <div className="header-button mt-4">
              <a href="contact.html" className="theme-btn text-center">
                <span>Get A Quote<i className="fa-solid fa-arrow-right-long" /></span>
              </a>
            </div>
            <div className="social-icon d-flex align-items-center">
              <a href="#"><i className="fab fa-facebook-f" /></a>
              <a href="#"><i className="fab fa-twitter" /></a>
              <a href="#"><i className="fab fa-youtube" /></a>
              <a href="#"><i className="fab fa-linkedin-in" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="offcanvas__overlay" />



<div>
  <div className="header-top-section">
    <div className="container-fluid">
      <div className="header-top-wrapper">
        <ul className="contact-list">
          <li>
            <i className="fas fa-envelope" />
            <a href="mailto:info@example.com" className="link">info@inamsolutions.com</a>
          </li>
          <li>
            <i className="fas fa-map-marker-alt" />
            Somajiguda,Hyderabad,Telangana
          </li>
        </ul>
        <div className="header-top-right">
          <ul className="top-list">
            <li><a href="contact.html">Help</a></li>
            <li>/</li>
            <li><a href="contact.html">Support</a></li>
            <li>/</li>
            <li><a href="contact.html">Contact</a></li>
          </ul>
          <div className="social-icon d-flex align-items-center">
            <a href="#"><i className="fab fa-twitter" /></a>
            <a href="#"><i className="fa-brands fa-facebook" /></a>
            <a href="#"><i className="fa-brands fa-pinterest-p" /></a>
            <a href="#"><i className="fa-brands fa-instagram" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <header id="header-sticky" className="header-1" style={{backgroundColor: '#fff'}}>
    <div className="container-fluid">
      <div className="mega-menu-wrapper">
        <div className="header-main">
          <div className="header-left">
            <div className="logo">
              <a href="index.html" className="header-logo">
                <img src="assets/img/logo/inam.jpg" alt="logo-img" />
              </a>
            </div>
            <div className="mean__menu-wrapper">
              <div className="main-menu">
                <nav id="mobile-menu">
                  <ul>
                    <li className="has-dropdown active menu-thumb">
                    <Link to="/" style={{ color: 'rgb(82, 80, 80)' }}>
  Home
  {/* <i className="fas fa-angle-down"></i> */}
</Link>
                    </li>
                    <li>
  <Link to="/aboutus" style={{ color: 'rgb(82, 80, 80)' }}>
    About Us
  </Link>
</li>
                    {/* <li class="has-dropdown">
                                          <a href="news.html" style="color:rgb(82, 80, 80)">
                                              Products
                                              <i class="fas fa-angle-down"></i>
                                          </a>
                                          <ul class="submenu" >
                                              <li><a href="gallery.html" style="color:blue;">Drilling Rigs</a></li>
                                              <li><a href="faq.html" style="color:blue;">Mining and Exploration Rigs</a></li>
                                              <li><a href="faq.html" style="color:blue;">Tractor Implements</a></li>
                                              <li><a href="faq.html" style="color:blue;">RFB Rigs List</a></li>
                                          </ul>
                                      </li> */}
                    <li>
                    <Link to="/products">
  Products
  <i className="fas fa-angle-down" />
</Link>
<ul className="submenu">
  <li>
    <Link to="/Drillingrigs" style={{ color: 'blue' }}>
      Drilling Rigs
    </Link>
  </li>
  <li>
    <Link to="/MAERigs" style={{ color: 'blue' }}>
      Mining & Exploration Rigs
    </Link>
  </li>
  <li>
    <Link to="/Tractor" style={{ color: 'blue' }}>
      Tractor Implements
    </Link>
  </li>
  <li>
    <Link to="/RFBRigs" style={{ color: 'blue' }}>
      RFB Rigs List
    </Link>
  </li>
</ul>
                    </li>
                    <li>
                    <Link to="/Spares&Accessories" style={{ color: 'rgb(82, 80, 80)' }}>
  Spare's & Accessories
  <i className="fas fa-angle-down" />
</Link>
                      <ul className="submenu">
                      <li>
  <Link to="/Draccessories" style={{ color: 'blue' }}>
    Drilling Rigs Accessories
  </Link>
</li>
<li>
  <Link to="/Refurbished" style={{ color: 'blue' }}>
    Refurbished Products
  </Link>
</li>
                      </ul>
                    </li>
                    <li>
  <Link to="/contact">
    Contact
  </Link>
</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <style dangerouslySetInnerHTML={{__html: "\n  \n                            mean__menu-wrapper {\n    max-width: 1200px; /* Set the maximum width of the menu */\n    margin: 0 auto; /* Center the menu within its container */\n} */\n.header-1 {\n    height: 60px; /* Adjust the height as needed */\n}\n\n.header-main {\n    padding: 0% 0; /* Adjust padding values as needed */\n}\n\n.header-left {\n    padding: 0; /* Remove or adjust padding as needed */\n}\n\n" }} />
          {/* <div class="header-right d-flex justify-content-end align-items-center">
                      <div class="icon-items">
                          <div class="icon">
                              <i class="fas fa-phone-alt"></i>
                          </div>
                          <div class="content">
                              <p>Call Anytime</p>
                              <h6><a href="tel:+9288009850">+92 (8800) - 9850</a></h6>
                          </div>
                       </div> 
                      <a href="#0" class="search-trigger search-icon"><i class="fa-regular fa-magnifying-glass"></i></a> */}
          {/* <div class="header-button">
                          <a href="car-details.html" class="header-btn">
                              Find a Car
                          </a>
                      </div> */}
          {/* <ul class="nav-bar-tools d-flex align-items-center justify-content-between">

                          <li class="item buttons">
                              <a class="btn-1 btn-3" href="contact"><span>Request A Quote</span></a>
                          </li>
                          <li class="item">
                                  <span class="search-icon open"><i class="fas fa-search"></i></span>
                              </li> 
                      </ul> */}
          <div className="header__hamburger d-xl-none my-auto">
            <div className="sidebar__toggle">
              <i className="fas fa-bars" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>





    
    </>;

}

export default Navbar;
