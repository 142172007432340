import React from 'react';
import './index.css'
import { Link } from 'react-router-dom';


function Carousel() {
  return (
    <div>
      {/* Carousel */}
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval={2000}>
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={3} aria-label="Slide 4" />
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={4} aria-label="Slide 5" />
        </div>
        <div className="carousel-inner">
          {/* Carousel Item 1 */}
          <div className="carousel-item active move-outwards">
            <div className="services-carousel-item item-1">
              <Link to="/aboutus" className="services-item-img-box" style={{ backgroundImage: 'url(assets/images/all/products/2.jpg)' }} />

              <div className="item-box">
                <i className="ar-icons-tank" />
                <div className="content-box">
                  <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/shield (1).png"} alt="Shield" />


                  <h4>Our Facilities</h4>
                  <p>We pride ourselves on our state-of-the-art facilities that enable us to deliver exceptional products and services to our valued customers.</p>
                  <Link className="more" to="/aboutus">Read More</Link>

                </div>
              </div>
            </div>
          </div>
          {/* Carousel Item 2 */}
          <div className="carousel-item">
            <div className="services-carousel-item item-2">
            <Link to="/aboutus" className="services-item-img-box" />

              <div className="item-box">
                <i className="ar-icons-worker" />
                <div className="content-box">
                <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/digital-services (1).png"} alt="Digital Services" />

                  <h4>Design &amp; Development</h4>
                  <p>We are passionate about pushing boundaries, shaping ideas, and transforming concepts into reality.</p>
                  <Link className="more" to="/about">Read More</Link>

                </div>
              </div>
            </div>
          </div>
          {/* Carousel Item 3 */}
          <div className="carousel-item">
            <div className="services-carousel-item item-3">
            <Link to="/about" className="services-item-img-box" style={{ backgroundImage: 'url(assets/images/all/products/4.jpg)' }} />

              <div className="item-box">
                <i className="ar-icons-factory" />
                <div className="content-box">
                <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/worker (1).png"} alt="Worker" />

                  <h4>Manufacturing</h4>
                  <p>With a strong focus on quality and innovation, we are committed to delivering exceptional products that meet the needs and expectations of our customers.</p>
                  <Link className="more" to="/about">Read More</Link>

                </div>
              </div>
            </div>
          </div>
          {/* Carousel Item 4 */}
          <div className="carousel-item">
            <div className="services-carousel-item item-4">
            <Link to="/aboutus" className="services-item-img-box" style={{ backgroundImage: 'url(assets/images/all/products/5.jpg)' }} />

              <div className="item-box">
                <i className="ar-icons-plan-1" />
                <div className="content-box">
                  <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/achievement (1).png"} alt="Achievement" />

                  <h4>Quality Control</h4>
                  <p>Our Quality Control department is at the heart of our operations, ensuring that every product that leaves our facilities meets stringent quality standards.</p>
                  <Link className="more" to="/about">Read More</Link>

                </div>
              </div>
            </div>
          </div>
          {/* Carousel Item 5 */}
          <div className="carousel-item">
            <div className="services-carousel-item item-4">
            <Link to="/about" className="services-item-img-box" style={{ backgroundImage: 'url(assets/images/all/products2/5.png)' }} />

              <div className="item-box">
                <i className="ar-icons-factory-machine" />
                <div className="content-box">
                <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/mining-truck (1).png"} alt="Mining Truck" />

                  <h4>Mining Equipment</h4>
                  <p>Reliable and efficient machinery designed to optimize performance and ensure safety across all mining operations, including excavation.</p>
                  <Link className="more" to="/about">Read More</Link>

                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" style={{paddingTop:120}}>
          <span className="carousel-control-prev-icon" aria-hidden="true" style={{backgroundColor:'black'}} />
          <span className="visually-hidden" >Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" style={{paddingTop:120}}>>
          <span className="carousel-control-next-icon" aria-hidden="true" style={{backgroundColor:'black'}} />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
