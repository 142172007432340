
import './App.css';
import Footer from './components/footer';  // Ensure proper casing for file names
import Navbar from './components/navbar';
import {Routes, Route} from 'react-router-dom';
import FooterTop from './components/FooterTop';
import Home from './containers/Home';
import Aboutus from './containers/aboutus';
import Contact from './containers/Contact';
import DrillingRigs from './containers/Drillingrigs';
import MAERigs from './components/MAERigs';
import Daccessories from './containers/Daccessories';
import Rfb from './containers/RFB';
import Tractor from './containers/Tractor';
import RFBRigs from './containers/RFB Rigs';

function App() {
  return (
    <>
   
      <Navbar />
      

      <Routes>

       <Route path='/' element={<Home/>}/>
      <Route path='/aboutus' element={<Aboutus />} />
      <Route path='/Drillingrigs' element={<DrillingRigs/>} />
      <Route path='/MAERigs' element={<MAERigs/>} />
      <Route path='/Tractor' element={<Tractor/>} />
      <Route path='/RFBRigs' element={<RFBRigs/>} />
      <Route path='/Draccessories' element={<Daccessories/>} />
      <Route path='/Refurbished' element={<Rfb/>} />
      <Route path='/contact' element={<Contact />} />



        </Routes>

      <FooterTop/>
      <Footer />
    </>
  );
}

export default App;
