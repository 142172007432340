import React from 'react';

import { Link } from 'react-router-dom';


function Rfb() {
  return (
    <div>
      <div>
        <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: 'url("assets/images/Refurbished/banner.jpg")' }}>
          <div className="container">
            <div className="page-heading">
              <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".3s">
                <li>
                <Link to="/">Home</Link>

                </li>
                <li>
                  <i className="fas fa-chevron-right" />
                </li>
                <li>Refurbished Products</li>
              </ul>
              <h1 className="wow fadeInUp" data-wow-delay=".5s">Refurbished Products</h1>
            </div>
          </div>
        </div>
        
        {/* Faq Section Start */}
        <section className="faq-section-2 fix section-padding">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-4">
                <div className="faq-sidebar">
                  <div className="search-widget">
                    <form action="#">
                      <input type="text" placeholder="Search" />
                      <button type="submit">
                        <i className="fa-regular fa-magnifying-glass" />
                      </button>
                    </form>
                  </div>
                  <h3>
                    Still have Questions? <br />
                    Call Now!
                  </h3>
                  <div className="icon-items">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="content">
                      <p>Call Anytime</p>
                      <h6>
                        <a href="tel:+923076806860">+ 91 9030503222</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="faq-content">
                  <div className="section-title">
                    {/* <img src="assets/img/sub-icon.png" alt="icon-img" className="wow fadeInUp" /> */}
                    <span className="wow fadeInUp" data-wow-delay=".2s">REFURBISHED PRODUCTS </span>
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">
                      Quality And Performance, Reimagined
                    </h2>
                    <p>
                      Experience exceptional value with our range of refurbished products, expertly restored to meet high standards of quality and performance. Our refurbished items provide a cost-effective solution without compromising on reliability or functionality.
                    </p>
                  </div>
                  <div className="faq-accordion mt-4 mt-md-0">
                    <div className="accordion" id="accordion">
                      <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".3s">
                        <h3 style={{ fontSize: 29, fontWeight: 500, marginBottom: 9, color: 'blue' }}>
                          Why Choose Refurbished?
                        </h3>
                        <h5 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                            Expert Restoration
                          </button>
                        </h5>
                        <div id="faq1" className="accordion-collapse collapse" data-bs-parent="#accordion">
                          <div className="accordion-body">
                            <p>
                              <strong className="strg-head">Thorough Inspection: </strong> Each product undergoes a comprehensive evaluation to ensure it meets our rigorous standards.
                            </p>
                            <p>
                              <strong className="strg-head">Professional Repairs: </strong> Our skilled technicians address any issues and replace worn or damaged parts with high-quality components.
                            </p>
                            <p>
                              <strong className="strg-head">Quality Assurance: </strong> Every refurbished item is tested for optimal performance and reliability before it reaches you.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".5s">
                        <h5 className="accordion-header">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                            Cost Effective Solution
                          </button>
                        </h5>
                        <div id="faq2" className="accordion-collapse show" data-bs-parent="#accordion">
                          <div className="accordion-body">
                            <p>
                              <strong className="strg-head">Significant Savings: </strong> Enjoy the benefits of high-quality products at a fraction of the cost of new ones.
                            </p>
                            <p>
                              <strong className="strg-head">Value for Money: </strong> Refurbished items offer excellent performance and longevity, making them a smart investment for budget-conscious buyers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".7s">
                        <h5 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                            Eco-friendly Choice
                          </button>
                        </h5>
                        <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                          <div className="accordion-body">
                            <p>
                              <strong className="strg-head">Sustainable Practices: </strong> By choosing refurbished products, you contribute to reducing waste and minimizing environmental impact.
                            </p>
                            <p>
                              <strong className="strg-head">Resource Efficiency: </strong> Extending the life cycle of products helps conserve resources and supports a circular economy.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".5s">
                        <h5 className="accordion-header">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                            Industrial Machinery
                          </button>
                        </h5>
                        <div id="faq4" className="accordion-collapse show" data-bs-parent="#accordion">
                          <div className="accordion-body">
                            <p>
                              <strong className="strg-head">Robust Solutions: </strong> Our refurbished industrial machinery is designed to deliver consistent performance for a wide range of applications.
                            </p>
                            <p>
                              <strong className="strg-head">Enhanced Value: </strong> Refurbished items offer excellent performance and longevity, making them a smart investment for budget-conscious buyers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".3s">
                        <h5 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                            Electronic Equipment
                          </button>
                        </h5>
                        <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                          <div className="accordion-body">
                            <p>
                              <strong className="strg-head">Upgrade Technology: </strong> Get the latest features and performance with electronic equipment that has been professionally refurbished.
                            </p>
                            <p>
                              <strong className="strg-head">Reliable Operation: </strong> Each item is tested to ensure it meets our high standards of functionality and safety.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".7s">
                        <h5 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq6" aria-expanded="false" aria-controls="faq6">
                            Why Buy From Us
                          </button>
                        </h5>
                        <div id="faq6" className="accordion-collapse collapse" data-bs-parent="#accordion">
                          <div className="accordion-body">
                            <p>
                              <strong className="strg-head">Trusted Expertise: </strong> Our team has extensive experience in refurbishing and restoring products to their optimal condition.
                            </p>
                            <p>
                              <strong className="strg-head">Comprehensive Warranty: </strong> Enjoy peace of mind with our warranty coverage, ensuring that your refurbished product performs as expected.
                            </p>
                            <p>
                              <strong className="strg-head">Customer Support: </strong> Our dedicated support team is here to assist you with any questions or concerns about your refurbished purchase.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Rfb;
