import React from 'react';

import { Link } from 'react-router-dom';


function Features() {
  return (
    <div>
      <section className="feature-benefit section section-padding fix">
        <div className="container">
          <div className="section-title text-center">
            {/* <img src="../../public/assets/img/sub-icon.png" alt="icon-img" class="wow fadeInUp"> */}
            <span className="wow fadeInUp" data-wow-delay=".2s" />
            <h2 className="wow fadeInUp" data-wow-delay=".4s">
              Our Products
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="feature-benefit-items">
                <div className="feature-content" style={{ padding: 0 }}>
                  <img
                    src="assets\images\home\drilling-rig.jpg"
                    style={{ width: 330, height: 300 }}
                    alt="Crawel Logo"
                  />
                </div>
                <div className="feature-button">
                <Link to="/drillingrigs" className="link-btn">
  {/* You can add text or children here, e.g., "Drilling Rigs" */}


                    Drilling Rigs <i className="fa-solid fa-arrow-right ps-1" />
                    </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="feature-benefit-items">
                <div className="feature-content" style={{ padding: 0 }}>
                  <img
                    src="assets\images\home\mining-and-exploration-rig.jpg"
                    style={{ width: 330, height: 300 }}
                    alt="Mounted Crane"
                  />
                </div>
                <div className="feature-button">
                <Link to="/MAERigs" className="link-btn">
  {/* Add your text or children here, e.g., "Truck Mounted" */}


                    Mining & Exploration Rigs <i className="fa-solid fa-arrow-right ps-1" />
                    </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="feature-benefit-items">
                <div className="feature-content" style={{ padding: 0 }}>
                  <img
                    src="assets\images\home\tractor-implements.jpg"
                    style={{ width: 330, height: 300 }}
                    alt="Crawel"
                  />
                </div>
                <div className="feature-button">
                <Link to="/Tractor" className="link-btn">
Tractor Implements<i className="fa-solid fa-arrow-right ps-1" />
</Link>

                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="feature-benefit-items">
                <div className="feature-content" style={{ padding: 0 }}>
                  <img
                    src="assets\images\home\rfb-rig.jpg"
                    style={{ width: 330, height: 300 }}
                    alt="Skid Mounted"
                  />
                </div>
                <div className="feature-button">
                <Link to="/RFBRigs" className="link-btn">RFB Rigs<i className="fa-solid fa-arrow-right ps-1" />
</Link>

                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="feature-benefit-items">
                <div className="feature-content" style={{ padding: 0 }}>
                  <img
                    src="assets\images\home\drilling-rig-accessories.jpg"
                    style={{ width: 330, height: 300 }}
                    alt="Mounted Crane"
                  />
                </div>
                <div className="feature-button">
                <Link to="/Draccessories" className="link-btn">Drilling Accessories<i className="fa-solid fa-arrow-right ps-1" />
</Link>

                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="feature-benefit-items">
                <div className="feature-content" style={{ padding: 0 }}>
                  <img
                    src="assets\images\home\refurbished-products.jpg"
                    style={{ width: 330, height: 300 }}
                    alt="Drill"
                  />
                </div>
                <div className="feature-button">
                <Link to="/Refurbished" className="link-btn">Refurbished Products <i className="fa-solid fa-arrow-right ps-1" />
</Link>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
