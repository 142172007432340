import React from "react";
import { Link } from 'react-router-dom';


function Footer() {
    return <>

        <footer className="footer-section fix">
  <div className="container">
    <div className="footer-widgets-wrapper">
      <div className="row justify-content-between">
        <div className="col-xl-3 col-lg-5 col-md-6 wow fadeInUp" data-wow-delay=".2s">
          {/* <img src="assets/img/logo/inma.png.jpg" alt="about-image"> */}
          <div className="single-footer-widget shape-map">
            <div className="widget-head">
              <h4>Contact</h4>
            </div>
            <div className="footer-content">
              <p>
                DurgaNagar(Durga Apartment)
                Hyderabad, Telangana
              </p>
              <ul className="contact-info">
                <li>
                  <i className="fa-regular fa-envelope" />
                  <a href="mailto:needhelp@company.com">info@inamsolutions</a>
                </li>
                <li>
                  <i className="fa-solid fa-phone-volume" />
                  <a href="tel:+91 9390282096">+ 91(903050)-3222</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".4s">
          <div className="single-footer-widget" style={{position: 'relative', left: '-15px'}}>
            <div className="widget-head">
              <h4>Contact</h4>
            </div>
            <ul className="list-items">
              <li>
              <Link to="/aboutus">
  About Us
</Link>

              </li>
              {/* <li>
                              <a href="car-details.html">
                                  New Cars
                              </a>
                          </li> */}
              <li>
                {/* <a href="news-details.html">
                  Latest News
                </a> */}
              </li>
              {/* <li>
                              <a href="gallery.html">
                                  Gallery
                              </a>
                          </li> */}
              <li>
              <Link to="/contact">
  Contact
</Link>

              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5 col-md-6 wow fadeInUp" data-wow-delay=".6s">
          <div className="single-footer-widget">
            <div className="widget-head">
              <h4>Spare Parts</h4>
              <br />
              <div className="icon">
                {/* <i class="far fa-plus"></i> */}
                <p>Drill Replcement parts</p>
                <p>DrillingRigs &amp; Accessories</p>
                <p>Rig Source Drill &amp; Tolling</p>
                <p>Drilling Equipment parts</p>
              </div>
            </div>
            {/* <div class="col-xl-4 col-lg-5 col-md-6 wow fadeInUp" data-wow-delay=".6s">
                          <div class="single-footer-widget">
                              <div class="widget-head">
                                  <h4>Spare Parts</h4>
                                  <br>
                                      <div class="icon">
                                          <i class="far fa-plus"></i> */}
            {/* <p>Drill Replcement parts</p>
                                          <p>DrillingRigs & Accessories</p>
                                          <p>Rig Source Drill & Tolling</p>
                                          <p>Drilling Equipment parts</p>
                                      </div>

                              </div> */}
            {/* <div class="footer-gallery">
                                  <div class="gallery-wrap">
                                      <div class="gallery-item">
                                          <div class="thumb">
                                              <a href="assets/img/footer/gallery-1.jpg" class="img-popup">
                                                  <img src="assets/img/footer/gallery-1.jpg" alt="gallery-img">
                                                      <div class="icon">
                                                          <i class="far fa-plus"></i>
                                                      </div>
                                              </a>
                                          </div>
                                          <div class="thumb">
                                              <a href="assets/img/footer/gallery-2.jpg" class="img-popup">
                                                  <img src="assets/img/footer/gallery-2.jpg" alt="gallery-img">
                                                      <div class="icon">
                                                          <i class="far fa-plus"></i>
                                                      </div>
                                              </a>
                                          </div>
                                          <div class="thumb">
                                              <a href="assets/img/footer/gallery-3.jpg" class="img-popup">
                                                  <img src="assets/img/footer/gallery-3.jpg" alt="gallery-img">
                                                      <div class="icon">
                                                          <i class="far fa-plus"></i>
                                                      </div>
                                              </a>
                                          </div>
                                      </div>
                                      <div class="gallery-item">
                                          <div class="thumb">
                                              <a href="assets/img/footer/gallery-4.jpg" class="img-popup">
                                                  <img src="assets/img/footer/gallery-4.jpg" alt="gallery-img">
                                                      <div class="icon">
                                                          <i class="far fa-plus"></i>
                                                      </div>
                                              </a>
                                          </div>
                                          <div class="thumb">
                                              <a href="assets/img/footer/gallery-5.jpg" class="img-popup">
                                                  <img src="assets/img/footer/gallery-5.jpg" alt="gallery-img">
                                                      <div class="icon">
                                                          <i class="far fa-plus"></i>
                                                      </div>
                                              </a>
                                          </div>
                                          <div class="thumb">
                                              <a href="assets/img/footer/gallery-6.jpg" class="img-popup">
                                                  <img src="assets/img/footer/gallery-6.jpg" alt="gallery-img">
                                                      <div class="icon">
                                                          <i class="far fa-plus"></i>
                                                      </div>
                                              </a>
                                          </div>
                                      </div> */}
            {/* </div>
                              </div> */}
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s">
          <div className="single-footer-widget">
            <div className="widget-head">
              <h4>Newsletter</h4>
            </div>
            <div className="footer-content">
              <p>Sign up for industry alerts, our latest news, thoughts, and insights from Inam Drilling Solutions</p>
              <div className="footer-input">
                <input type="email" id="email2" placeholder="Email address" />
                <button className="newsletter-btn" type="submit">
                  <i className="fa-regular fa-paper-plane" />
                  {/* <div className="social-icon d-flex align-items-center wow fadeInUp" data-wow-delay="
              .5s">
                    <a href="#"><i className="fab fa-facebook-f" /></a>
                    <a href="#"><i className="fab fa-twitter" /></a>
                    <a href="#"><i className="fa-brands fa-linkedin-in" /></a>
                    <a href="#"><i className="fa-brands fa-youtube" /></a>
                  </div> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="footer-wrapper">
        <p className="wow fadeInUp" data-wow-delay=".4s">
          © Copyright 2024 by <a href="https://www.inamsolutions.com">inamsolutions</a>
        </p>
      </div>
    </div>
  </div>
</footer>

    </>;
}

export default Footer;