import React from 'react';

function Gallery() {
  return (
    <div>
      {/* Gallery Section Start */}
      <div className="gallery-section fix mt-5">
        <div className="gallery-wrapper">
          <div className="row g-4">
            <div className="col-xxl-4 col-xl-5 col-lg-5">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="row g-4">
                    <div className="col-md-12">
                      <div className="gallery-image">
                        <img src={process.env.PUBLIC_URL + "assets/images/home/footersection1.jpg"} alt="img" />
                        <div className="icon-box">
                          <a href={process.env.PUBLIC_URL + "assets/images/home/fs3.jpg"} className="icon img-popup-2">
                            <i className="fa-solid fa-plus" />
                          </a>
                        </div>
                        <div className="mask" />
                        <div className="mask-second" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="gallery-image">
                        <img src={process.env.PUBLIC_URL + "assets/images/home/fs3.jpg"} alt="img" />
                        <div className="icon-box">
                          <a href={process.env.PUBLIC_URL + "assets/images/home/fs3.jpg"} className="icon img-popup-2">
                            <i className="fa-solid fa-plus" />
                          </a>
                        </div>
                        <div className="mask" />
                        <div className="mask-second" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="gallery-image">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/fs2.jpg"} alt="img" />
                    <div className="icon-box">
                      <a href={process.env.PUBLIC_URL + "assets/images/home/fs2.jpg"} className="icon img-popup-2 style-two">
                        <i className="fa-solid fa-plus" />
                      </a>
                    </div>
                    <div className="mask" />
                    <div className="mask-second" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-7 col-lg-7">
              <div className="row g-4">
                <div className="col-md-4 col-sm-6">
                  <div className="gallery-image">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/fs4.jpeg"} alt="img" />
                    <div className="icon-box">
                      <a href={process.env.PUBLIC_URL + "assets/images/home/fs4.jpeg"} className="icon img-popup-2">
                        <i className="fa-solid fa-plus" />
                      </a>
                    </div>
                    <div className="mask" />
                    <div className="mask-second" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="gallery-image">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/fs5.jpg"} alt="img" />
                    <div className="icon-box">
                      <a href={process.env.PUBLIC_URL + "assets/images/home/fs5.jpg"} className="icon img-popup-2">
                        <i className="fa-solid fa-plus" />
                      </a>
                    </div>
                    <div className="mask" />
                    <div className="mask-second" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="gallery-image">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/fs6.jpg"} alt="img" />
                    <div className="icon-box">
                      <a href={process.env.PUBLIC_URL + "assets/images/home/fs6.jpg"} className="icon img-popup-2">
                        <i className="fa-solid fa-plus" />
                      </a>
                    </div>
                    <div className="mask" />
                    <div className="mask-second" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="gallery-image">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/fs7.jpg"} alt="img" />
                    <div className="icon-box">
                      <a href={process.env.PUBLIC_URL + "assets/images/home/fs7.jpg"} className="icon img-popup-2">
                        <i className="fa-solid fa-plus" />
                      </a>
                    </div>
                    <div className="mask" />
                    <div className="mask-second" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="gallery-image">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/fs8.jpg"} alt="img" />
                    <div className="icon-box">
                      <a href={process.env.PUBLIC_URL + "assets/images/home/fs8.jpg"} className="icon img-popup-2">
                        <i className="fa-solid fa-plus" />
                      </a>
                    </div>
                    <div className="mask" />
                    <div className="mask-second" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-6 col-sm-6">
              <div className="gallery-image style-2">
                <img src={process.env.PUBLIC_URL + "assets/images/home/fs9.jpg"} alt="img" />
                <div className="icon-box">
                  <a href={process.env.PUBLIC_URL + "assets/images/home/fs9.jpg"} className="icon img-popup-2 style-two">
                    <i className="fa-solid fa-plus" />
                  </a>
                </div>
                <div className="mask" />
                <div className="mask-second" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
