import React from 'react';
import { Link } from 'react-router-dom';


function About() {
  return (
    <div>
      {/* About Section Start */}
      <section className="about-section fix section-padding pt-0">
        <div className="container">
          <div className="about-wrapper">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="about-image-items">
                  <div className="color-shape">
                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/about/secondary-shape-color-full.png"} alt="shape-img" /> */}
                  </div>
                  <div className="car-shape wow fadeInUp" data-wow-delay=".7s">
                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/about/car-shape.png"} alt="shape-img" /> */}
                  </div>
                  <div className="counter-content wow fadeInLeft" data-wow-delay=".4s">
                    <h2 className="text-white"><span className="count">10</span>+</h2>
                    <p className="text-white">
                      Professional <br />
                      Experts
                    </p>
                  </div>
                  <div className="about-image-1 wow fadeInDown" data-wow-delay=".3s">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/service.jpg"} alt="about-image" />
                  </div>
                  <div className="about-image-2 wow fadeInLeft" data-wow-delay=".5s">
                    <img src={process.env.PUBLIC_URL + "assets/images/home/service2.jpg"} alt="about-image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <div className="section-title">
                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/sub-icon.png"} alt="icon-img" className="wow fadeInUp" /> */}
                    <span className="wow fadeInUp" data-wow-delay=".2s">Get to know us</span>
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">
                        <br />
                      Drilling Solutions
                    </h2>
                  </div>
                  <h4 className="mt-3 mt-md-0 wow fadeInUp" data-wow-delay=".3s">
                    Exceptional Drilling Service Excellence.
                  </h4>
                  <p className="wow fadeInUp" data-wow-delay=".5s" style={{ fontFamily: 'sans-serif', color: 'rgb(76, 71, 71)', fontWeight: 2, fontSize: 18 }}>
                    We stand at the forefront of innovation and excellence in mining and water well drilling equipment. With a rich legacy of empowering projects worldwide, we provide the drilling industry with unmatched precision, efficiency, and versatility.
                  </p>
                  <div className="about-list-item wow fadeInUp" data-wow-delay=".7s">
                    <ul>
                      <li>
                        <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/icons8-cart-50.png"} alt="about-image" />
                        Top Rated
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/icons8-hand-tools-100 (1).png"} alt="about-image" />
                        Offering Low Prices
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/icons8-drilling-rig-68 (1).png"} alt="about-image" />
                        Global Expertise
                      </li>
                      <li>
                        <img src={process.env.PUBLIC_URL + "assets/img/logo/resized-images/icons8-foundation-drilling-rig-100 (1).png"} alt="about-image" />
                        Eco-Friendly Practices
                      </li>
                    </ul>
                  </div>
                  <Link to="/aboutus" className="theme-btn wow fadeInUp" data-wow-delay=".8s">
  Discover More
</Link>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
