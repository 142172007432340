import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import Coordinators from "../../components/Coordinators";
import Map from "../Map"

function Contact() {
  return (
    <div>
      <div
        className="breadcrumb-wrapper bg-cover"
        style={{ backgroundImage: 'url("assets/images/Contact/banner.jpg")' }}
      >
        <div className="container">
          <div className="page-heading">
            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".3s">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fas fa-chevron-right" />
              </li>
              <li>Contact</li>
            </ul>
            <h1 className="wow fadeInUp" data-wow-delay=".5s">Contact</h1>
          </div>
        </div>
      </div>

      <section className="contact-section-1 fix section-padding pb-0 mb-16">
        <div className="container" style={{ paddingBottom: '20px' }}>
          <div className="contact-wrapper-area">
            <div className="row g-4">
              <div className="col-lg-9">
                <div className="contact-content">
                  <div className="section-title">
                    <span className="wow fadeInUp" data-wow-delay=".2s">Contact Us</span>
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">Drop us a Line</h2>
                  </div>
                  <form
                    action="#"
                    id="contact-form"
                    method="POST"
                    className="contact-form-items mt-5 mt-md-0"
                  >
                    <div className="row g-4">
                      <div className="col-lg-6">
                        <div className="form-clt">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-clt">
                          <input
                            type="email"
                            name="email"
                            id="email2"
                            placeholder="Your Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-clt">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-clt">
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="Phone"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-clt">
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Write a Comment"
                            required
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <button type="submit" className="theme-btn">Send a Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="contact-right-items">
                  <div className="contact-img">
                   
                  </div>
                  <div className="icon-items">
                    <div className="icon">
                      <img src="assets/img/question.png" alt="Question Icon" />
                    </div>
                    <div className="content">
                      <p>Have a Question?</p>
                      <h6>
                        <Link to="tel:+923076806860">+92 30 7680 6860</Link>
                      </h6>
                    </div>
                  </div>
                  <div className="icon-items">
                    <div className="icon">
                      <img src="assets/img/email.png" alt="Email Icon" />
                    </div>
                    <div className="content">
                      <p>Write Email</p>
                      <h6>
                        <a href="mailto:info@inamsolutions.com" className="link">info@inamsolutions.com</a>
                      </h6>
                    </div>
                  </div>
                  <div className="icon-items">
                    <div className="icon">
                      <img src="assets/img/location.png" alt="Location Icon" />
                    </div>
                    <div className="content">
                      <p>Visit Office</p>
                      <h6>
                        Durga Nagar, Somajiguda, Hyderabad <br />
                        Telangana
                      </h6>
                    </div>
                  </div>
                  <div className="social-icon d-flex align-items-center wow fadeInUp" data-wow-delay=".5s">
              <a href="https://www.facebook.com/YourPage" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="https://twitter.com/YourProfile" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter" />
              </a>
              <a href="https://wa.me/9030503222" target="_blank" rel="noopener noreferrer">
  <i className="fab fa-whatsapp" />
</a>

              <a href="https://www.youtube.com/c/YourChannel" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-youtube" />
              </a>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Map/>
    </div>
  );
}

export default Contact;
