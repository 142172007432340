import React from 'react';
import { Link } from 'react-router-dom';


function DrillingRigs() {
  return (
    <>
      <div>
        {/* << Breadcrumb Section Start >> */}
        <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: 'url("assets/images/drilling/banner.jpg")' }}>
          <div className="container">
            <div className="page-heading">
              <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".3s">
              <li>
  <Link to="/">Home</Link>
</li>

                <li>
                  <i className="fas fa-chevron-right" />
                </li>
                <li>
  <Link to="/drillingrigs">Drilling Rigs</Link>
</li>

              </ul>
              <h1 className="wow fadeInUp" data-wow-delay=".5s">Drilling Rigs</h1>
            </div>
          </div>
        </div>

        
      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div id="productCarousel1" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel1" data-bs-slide-to={0} className="active" />
                      <li data-bs-target="#productCarousel1" data-bs-slide-to={1} />
                      <li data-bs-target="#productCarousel1" data-bs-slide-to={2} />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\Mining\Truck1.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Truck Mounted Drilling Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\Mining\Truck2.jpg" className="d-block w-100 img-fluid" alt="Product Image 2" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Truck Mounted Drilling Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\Mining\Truck3.jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Truck Mounted Drilling Rigs</p>
                        </div>
                      </div>
                    </div>
                    {/* Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel1" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel1" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 90 }}>
                <h3>TruckMounted  Rigs</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                Inam Drilling Solutions' truck-mounted drilling rigs combine mobility with power for maximum efficiency. Designed for fast setup and seamless operation, these rigs are ideal for time-sensitive projects where accessibility is key. Mounted on durable truck platforms, they are versatile enough to handle a wide range of drilling applications, from water wells to exploration. Perfect for remote locations, our rigs offer easy relocation and are equipped with advanced technology for precise and efficient drilling.. Renowned for their speed and cost-efficiency, RC drilling rigs are ideal for exploration applications. Our rigs feature robust construction and powerful compressors, ensuring performance in challenging environments. Additionally, we offer a comprehensive range of spare parts to maintain peak operational efficiency, minimizing downtime and s</p>
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong> INAM-3000X</li>
                    <li><strong>Engine Power:</strong> 450 HP Turbo Disel</li>
                    <li><strong>Drilling Depth:</strong>Upto 500 meters</li>

                    <li><strong>Drilling Diameter:</strong>6-12 inches</li>
                    <li><strong>Rotary speed:</strong> 0-120 RPM</li>
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">Delivers high impact energy for quick and efficient drilling.
                        .
                        .
                        </li>
                        <li className="mt-2">Engineered for optimal energy transfer with minimal wear.

                        </li>
                        <li className="mt-2">Versatile across mining, construction, and exploration applications.

                        </li>
                        <li className="mt-2">Built for durability and consistent performance in challenging environments.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div id="productCarousel2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel2" data-bs-slide-to={0} className="active" style={{color:'white'}} />
                      <li data-bs-target="#productCarousel2" data-bs-slide-to={1} style={{color:'white'}} />
                      <li data-bs-target="#productCarousel2" data-bs-slide-to={2} style={{color:'white'}}  />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\Mining\Skid1.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          {/* <p>Skid Mounted Rigs</p> */}
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\Mining\Skid2.jpg" className="d-block w-100 img-fluid" alt="Product Image 2" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          {/* <p>Skid Mounted Drilling Rigs</p> */}
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\Mining\Skid3.jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          {/* <p>Skid Drilling Rigs</p> */}
                        </div>
                      </div>
                    </div>
                    {/* Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel2" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel2" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>



            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 90 }}>
                <h3>Skid-Mounted Rigs</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                Inam Drilling Solutions' skid-mounted drilling rigs are engineered for efficiency and adaptability. These versatile rigs are designed to be mounted on a skid base, offering a stable and compact solution for various drilling needs. Ideal for both on-site and remote operations, our skid-mounted rigs are perfect for projects where space and maneuverability are crucial. With robust construction and advanced technology, these rigs deliver reliable performance across a range of applications including water wells, mining, and geotechnical investigations.
                </p>
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong> INAM-3000S</li>
                    <li><strong>Engine Power:</strong> 350 HP Turbo Disel</li>
                    <li><strong>Drilling Depth:</strong>Upto 400 meters</li>

                    <li><strong>Drilling Diameter:</strong>5-10 inches</li>
                    <li><strong>Rotary speed:</strong> 0-100 RPM</li>
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">Compact and stable design ensures reliable performance for stationary drilling.

                        .
                        </li>
                        <li className="mt-2">Ideal for deep drilling applications, engineered to handle significant depths with ease.

                        </li>
                        <li className="mt-2">Durable construction built to withstand harsh conditions and ensure long-term use.

                        </li>
                        <li className="mt-2">Easy to install and maintain, designed for straightforward setup and upkeep.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      
      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div id="productCarousel3" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel3" data-bs-slide-to={0} className="active" style={{color:'white'}} />
                      <li data-bs-target="#productCarousel3" data-bs-slide-to={1} style={{color:'white'}} />
                      <li data-bs-target="#productCarousel3" data-bs-slide-to={2} style={{color:'white'}} />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\Mining\crawler.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          {/* <p>Crawler Mounted Drilling Rigs</p> */}
                        </div>
                      </div>
                      <div className="carousel-item" stylele={{border:'2px solid black'}}>
                        <img src="assets\images\Mining\crawler2.jpg" className="d-block w-100 img-fluid" alt="Product Image2 " style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          {/* <p>Crawler Mounted Drilling Rigs</p> */}
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\Mining\crawler3.jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          {/* <p>Crawler Mounted Drilling Rigs</p> */}
                        </div>
                      </div>
                    </div>
                    {/* Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel3" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel3" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 90 }}>
                <h3>Crawler Mounted Rigs</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                For challenging terrains and harsh environments, Inam Drilling Solutions' crawler-mounted drilling rigs deliver unmatched stability and versatility. Engineered to navigate rough surfaces effortlessly, these rigs provide a robust and stable platform for drilling in the most demanding conditions. Ideal for mining, geotechnical, and water well applications, our crawler-mounted rigs ensure peak productivity and safety. Equipped with powerful engines and advanced drilling technology, these rigs are designed to excel under pressure</p>
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong>Crawler-Mounted Pro X500</li>
                    <li><strong>Engine Power:</strong> 350 HP Turbo Disel</li>
                    <li><strong>Drilling Depth:</strong>Upto 500 meters</li>

                    <li><strong>Drilling Diameter:</strong>200-300 mm</li>
                    <li><strong>Rotary speed:</strong> 0-100 RPM</li>
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">Superior mobility across rugged and uneven terrain

                        .
                        </li>
                        <li className="mt-2">Enhanced stability for secure and efficient drilling

                        </li>
                        <li className="mt-2">Adaptable to diverse geological conditions
                        </li>
                        <li className="mt-2">Engineered for heavy-duty use with a focus on durability</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

       </div>
    </>
  );
}

export default DrillingRigs;