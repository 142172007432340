import React from 'react';
import { Link } from 'react-router-dom';


function aboutus() {
  return (
    <div>
     
      <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: 'url("assets/images/about/banner.jpg")' }}>
        <div className="container">
          <div className="page-heading">
            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".3s">
              <li>
              <Link to="/">Home</Link>

              </li>
              <li>
                <i className="fas fa-chevron-right" />
              </li>
              <li>About US</li>
            </ul>
            <h1 className="wow fadeInUp" data-wow-delay=".5s">About Us</h1>
          </div>
        </div>
      </div>
      <section className="news-details-section fix section-padding">
        <div className="container">
          <div className="news-details-wrapper">
            <div className="row g-5">
              <div className="col-lg-8">
                <div className="news-details-items">
                  <div className="news-image">
                    <img src="assets\images\about\products.jpg" alt="news-img" />
                  </div>
                  <div className="news-details-content">  
                    <ul className="list-admin">
                      <li>
                        <i className="fa-solid fa-circle-user" />
                        by Admin 
                      </li>
                      <li>/</li>
                      <li>
                        <i className="fa-solid fa-comments" />
                        2 Comments
                      </li>
                    </ul>
                    <h3>We Provide the Best Quality Service</h3>
                    <p className="mt-3">
                      At Inam Drilling Solutions, we offer a comprehensive range of products to cater to the diverse needs of the drilling industry. Our product lineup includes high-quality drill bits, hammers, and all essential mining equipment, engineered to perform efficiently in challenging conditions. We provide drilling rigs mounted on a variety of reliable trucks, such as MAN, Sino, AMW, Hino, Ashok Leyland, and DAF, ensuring flexibility and adaptability across various project requirements.
                    </p>
                    <p className="mt-4">
                      In addition to our robust truck-mounted rigs, we also supply Elgi and Atlas Copco compressors to guarantee optimal performance in even the most demanding environments. Our rigs are available in multiple configurations, including 6x4 and 6x6 truck-mounted options, as well as crawler-mounted rigs, capable of drilling depths ranging from 100 meters to 1200 meters. Whether for vertical or angular drilling, our equipment can handle diameters from 6 inches to 12 inches, providing precise and efficient drilling solutions.
                    </p>
                    <div className="features-style-2 mt-4">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="item">
                            <i className="bi bi-amd" />
                            <div className="content-box">
                              <ul style={{ color: 'rgb(85, 65, 65)' }}>
                                <li>
                                  <img src="assets/img/logo/resized-images/icons8-cart-50.png" alt="about-image" />
                                  Top Rated
                                  <p>Customized Rig Mobility: Featuring rigs mounted on an array of robust trucks from MAN to DAF, we bring you unmatched flexibility and ease in mobilization.</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="item">
                            <i className="ar-icons-trolley" />
                            <div className="content-box">
                              <ul>
                                <li style={{ color: 'rgb(85, 71, 71)' }}>
                                  <img src="assets/img/icon/bestqulaity_files/240_F_79423046_TjFrwAYuRrDztRomf180R60DNH52FhwZ.jpg" alt="about-image" style={{ color: 'black', width: 50 }} />
                                  Best Quality
                                  <p>Versatile Rig Configurations: With options ranging from 6x4 trucks to crawler-mounted rigs, adapt seamlessly to diverse terrains and project demands.</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-4">
                  <div className="col-lg-6">
                    <div className="news-rental-servce-items">
                      <h4>Looking for the best drilling equipment rental service</h4>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="news-rental-servce-items style-2">
                      <h4>Looking for the best drilling equipment rental service</h4>
                    </div>
                  </div>
                </div>
                <div className="comment-reviews">
                  <h3>2 Reviews</h3>
                  <div className="single-comment d-flex gap-4 pb-5">
                    <div className="image">
                      <img src="assets\images\about\review.jpg" alt="image" />
                    </div>
                    <div className="content">
                      <div className="head d-flex flex-wrap gap-3 align-items-center justify-content-between">
                        <div className="con">
                          <h4>Kevin Martin</h4>
                        </div>
                        
                      </div>
                      <p className="mt-4">
                        We have been using Inam Drilling Solutions' truck-mounted rigs for over a year, and the performance has been outstanding. The equipment is durable, handles various terrains, and the after-sales support is top-notch. We’ve successfully completed several deep drilling projects with minimal downtime. Highly recommended for anyone in the mining and exploration industry!
                      </p>
                    </div>
                  </div>
                  <div className="single-comment d-flex gap-4 pt-5 pb-5">
                    <div className="image">
                      <img src="assets\images\about\review2.jpg" alt="image" />
                    </div>
                    <div className="content">
                      <div className="head d-flex flex-wrap gap-3 align-items-center justify-content-between">
                        <div className="con">
                          <h4>Sarah Albert</h4>
                        </div>
                       
                      </div>
                      <p className="mt-4">
                        Inam Drilling Solutions provided us with both crawler-mounted rigs and drill bits, and we couldn't be more satisfied. The rigs are robust and efficient, especially in rough terrain, and the drilling bits last longer than others we've used. The customer service team was very helpful in providing technical support during setup. A great choice for serious drilling operations!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="comment-form-wrap pt-5">
                  <h3>Leave a comment</h3>
                  <form action="#" id="contact-form" method="POST">
                    <div className="row g-4">
                      <div className="col-lg-6">
                        <div className="form-clt">
                          <input type="text" name="name" id="name" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-clt">
                          <input type="text" name="email" id="email2" placeholder="Your Email" />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-clt">
                          <textarea name="message" id="message" placeholder="Write a Comment" defaultValue={""} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <button type="submit" className="theme-btn">
                          Submit Comment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="main-sidebar">
                  <div className="single-sidebar-widget">
                    <div className="wid-title">
                      <h3>Products</h3>
                    </div>
                    <div className="news-widget-categories">
                      <ul>
                      <li>
  <Link to="/drillingrigs" >
     Drilling Rigs<i className="fa-solid fa-arrow-right-long" />
  </Link>
</li>
<li>
  <Link to="/MAERigs" >
    Mining Exploration Rigs<i className="fa-solid fa-arrow-right-long" />
  </Link>
</li>
<li>
  <Link to="/Tractor">
    Tractor Implements<i className="fa-solid fa-arrow-right-long" />
  </Link>
</li>
<li>
  <Link to="/RFBRigs">
    RFB RigsList<i className="fa-solid fa-arrow-right-long" />
  </Link>
</li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default aboutus;
