import React from 'react';
import { Link } from 'react-router-dom';


function Daccessories() {
  return (
    <div>
      {/* Breadcrumb Section Start */}
      <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: 'url("assets/images/spares/banner.jpg")' }}>
        <div className="container">
          <div className="page-heading">
            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".3s">
              <li>
              <Link to="/">Home</Link>

              </li>
              <li>
                <i className="fas fa-chevron-right" />
              </li>
              <li>Spares And Accessories</li>
            </ul>
            <h1 className="wow fadeInUp" data-wow-delay=".5s">Spares And Accessories</h1>
          </div>
        </div>
      </div>
      {/* Faq Section Start */}
      <section className="faq-section-2 fix section-padding">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="faq-sidebar">
                <div className="search-widget">
                  <form action="#">
                    <input type="text" placeholder="Search" />
                    <button type="submit"><i className="fa-regular fa-magnifying-glass" /></button>
                  </form>
                </div>
                <h3>
                  Still have Questions? <br />
                  Call Now!
                </h3>
                <div className="icon-items">
                  <div className="icon">
                    <i className="fas fa-phone-alt" />
                  </div>
                  <div className="content">
                    <p>Call Anytime</p>
                    <h6><a href="tel:+923076806860">+ 91 9030503222</a></h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="faq-content">
                <div className="section-title">
                  {/* <img src="assets/img/sub-icon.png" alt="icon-img" className="wow fadeInUp" /> */}
                  <span className="wow fadeInUp" data-wow-delay=".2s">DRILLING RIGS ACCESSORIES</span>
                  <h2 className="wow fadeInUp" data-wow-delay=".4s">Enhance Your Drilling Efficiency</h2>
                  <p>
                    Unlock the full potential of your drilling rigs with our premium range of accessories, designed to boost performance and ensure seamless operation. From essential components to advanced add-ons, our accessories provide reliability and precision for all your drilling needs.
                  </p>
                </div>
                <div className="faq-accordion mt-4 mt-md-0">
                  <div className="accordion" id="accordion">
                    <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".3s">
                      <h5 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                          Drill Pipe And Tubing
                        </button>
                      </h5>
                      <div id="faq1" className="accordion-collapse collapse" data-bs-parent="#accordion">
                        <div className="accordion-body">
                          <p><strong className="strg-head">Durable Construction:</strong> Built to withstand high pressures and harsh environments.</p>
                          <p><strong className="strg-head">Precision Engineering:</strong> Ensures a secure and efficient connection for consistent drilling performance.</p>
                          <p><strong className="strg-head">Versatile Sizes:</strong> Available in various lengths and diameters to suit different drilling rigs and applications.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".5s">
                      <h5 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                          Drill Collars
                        </button>
                      </h5>
                      <div id="faq2" className="accordion-collapse show" data-bs-parent="#accordion">
                        <div className="accordion-body">
                          <p className="mt-4"><strong className="strg-head">Heavy-Duty Design:</strong> Provides the necessary weight to maintain proper drill bit penetration.</p>
                          <p className="mt-4"><strong className="strg-head">High Strength:</strong> Made from robust materials to handle extreme conditions and heavy loads.</p>
                          <p className="mt-4"><strong className="strg-head">Customizable Options:</strong> Available in various sizes and configurations for optimal performance.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item wow mb-4 fadeInUp" data-wow-delay=".7s">
                      <h5 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                          Stabilizers
                        </button>
                      </h5>
                      <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                        <div className="accordion-body">
                          <p className="mt-4"><strong className="strg-head">Enhanced Stability:</strong> Keeps the drill bit centered and reduces vibrations during drilling.</p>
                          <p className="mt-4"><strong className="strg-head">Rugged Construction:</strong> Built to endure the demands of challenging drilling environments.</p>
                          <p className="mt-4"><strong className="strg-head">Adaptable Design:</strong> Compatible with various rig setups and drilling conditions.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".3s">
                      <h5 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="true" aria-controls="faq4">
                          Mud Pumps And Equipment
                        </button>
                      </h5>
                      <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                        <div className="accordion-body">
                          <p className="mt-4"><strong className="strg-head">Efficient Fluid Circulation:</strong> Ensures optimal lubrication and cooling of the drill bit.</p>
                          <p className="mt-4"><strong className="strg-head">High Performance:</strong> Delivers reliable pressure and flow rates for consistent drilling.</p>
                          <p className="mt-4"><strong className="strg-head">Durable Materials:</strong> Designed to withstand the rigors of drilling operations.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item wow mb-4 fadeInUp" data-wow-delay=".7s">
                      <h5 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                          Top Drivers And Rotators
                        </button>
                      </h5>
                      <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                        <div className="accordion-body">
                          <p className="mt-4"><strong className="strg-head">Precision Control:</strong> Provides smooth and efficient rotation for enhanced drilling accuracy.</p>
                          <p className="mt-4"><strong className="strg-head">Heavy-Duty Performance:</strong> Engineered for high torque and reliability in demanding conditions.</p>
                          <p className="mt-4"><strong className="strg-head">Easy Maintenance:</strong> Features user-friendly design for straightforward upkeep and repairs.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item wow mb-4 fadeInUp" data-wow-delay=".7s">
                      <h5 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq6" aria-expanded="false" aria-controls="faq6">
                          BOPs (Blowout Preventers)
                        </button>
                      </h5>
                      <div id="faq6" className="accordion-collapse collapse" data-bs-parent="#accordion">
                        <div className="accordion-body">
                          <p className="mt-4"><strong className="strg-head">Critical Safety Feature:</strong> Prevents uncontrolled release of fluids and gases during drilling.</p>
                          <p className="mt-4"><strong className="strg-head">Robust Construction:</strong> Designed to handle high pressures and ensure operational safety.</p>
                          <p className="mt-4"><strong className="strg-head">Advanced Technology:</strong> Incorporates the latest innovations for reliable blowout prevention.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Daccessories;
