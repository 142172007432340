import React from 'react';
import { Link } from 'react-router-dom';


function FooterTop() {
  return (
    <div>
      <section className="cta-cheap-rental-section">
        <div className="container">
          <div className="cta-cheap-rental">
            <div className="cta-cheap-rental-left wow fadeInUp" data-wow-delay=".3s">
              <div className="logo-thumb" style={{ maxWidth: '50px', height: 'auto' }}>
              <Link to="/">
  Home
</Link>

                 
                  <img src={process.env.PUBLIC_URL + "/assets/img/logo/resized-images/inam (2).jpg"} alt="logo-img" />
                
              </div>
              <h4 className="text-white">Dig Deeper, Achieve More with Inam Drilling Solutions</h4>
            </div>
            <div className="social-icon d-flex align-items-center wow fadeInUp" data-wow-delay=".5s">
              <a href="https://www.facebook.com/YourPage" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="https://twitter.com/YourProfile" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter" />
              </a>
              <a href="https://wa.me/9030503222" target="_blank" rel="noopener noreferrer">
  <i className="fab fa-whatsapp" />
</a>

              <a href="https://www.youtube.com/c/YourChannel" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-youtube" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FooterTop;
