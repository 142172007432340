import React from 'react';
import { Link } from 'react-router-dom';


function RFBRigs()
{ 
    return (
        <>
          <div>
            {/* << Breadcrumb Section Start >> */}
            <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: 'url("assets/images/RFB/banner.jpg")' }}>
              <div className="container">
                <div className="page-heading">
                  <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".3s">
                  <li>
  <Link to="/">Home</Link>
</li>

                    <li>
                      <i className="fas fa-chevron-right" />
                    </li>
                    <li>
                    <Link to="/drillingrigs">RFB Rigs</Link>
                    </li>
                    <li>
                      {/* <i className="fas fa-chevron-right" /> */}
                    </li>
                    {/* <li className="breadcrumb-item active" aria-current="page">Crawler Mounted Drilling Rigs</li> */}
                  </ul>
                  <h1 className="wow fadeInUp" data-wow-delay=".5s">RFB Rigs</h1>
                </div>
              </div>
            </div>
    
            {/* Product Description Section */}
            
      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div id="productCarousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel" data-bs-slide-to={0} className="active" />
                      <li data-bs-target="#productCarousel" data-bs-slide-to={1} />
                      <li data-bs-target="#productCarousel" data-bs-slide-to={2} />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\Tractor\compressor.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>RFB Compressor</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\Tractor\compressor2.jpg" className="d-block w-100 img-fluid" alt="Product Image 2" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>RFB Compressor</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets/img/car/drilling3.jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>RFB Compressor</p>
                        </div>
                      </div>
                    </div>
                    {/* Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 20 }}>
                <h3>RFB Compressors</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                Inam Drilling Solutions' RFB Compressors are built for top-tier performance and durability in demanding drilling environments. These compressors deliver reliable and consistent airflow, essential for effective drilling operations. Designed with cutting-edge technology and robust components, our RFB Compressors ensure high efficiency and reduced downtime. Perfect for a range of applications from mining to construction, they offer the power and reliability needed to enhance your operational productivity.

                </p>
                {/* Product Features Box */}
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong>RFB-3000</li>
                    <li><strong>Airflow Capacity:</strong>3000 CFM</li>
                    <li><strong>Pressure Range:</strong>Upto 350 PSI</li>
                    <li><strong>Engine Power:</strong>600 HP</li>

                   
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">
                        Provides consistent airflow and pressure for optimal drilling performance.


                        </li>
                        <li className="mt-2">Engineered for high reliability and durability in challenging conditions.


                        .
                        </li>
                        <li className="mt-2">Utilizes advanced technology for improved operational efficiency.

                        </li>
                        <li className="mt-2">Heavy-duty construction for long-term use and reduced maintenance</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
          </div>
        </>
      );
    }




export default RFBRigs;