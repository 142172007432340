import React from 'react';
import Hero from '../../components/Hero';
import Features from '../../components/Features';
import Carousel from '../../components/carousel';
import Gallery from '../../components/Gallery';
import About from '../../components/About';


function Home() {
  return (
    <>
    
    <Hero />
      <Features />
      <About />
      <Carousel/>
      <Gallery/>
    
    
    </>
    
  )
}

export default Home
