import React from 'react';

function Hero() {
  return (
    <>
      <div>
        <section className="hero-section hero-1 fix">
          <div className="array-button">
            <button className="image-array-left">
              <i className="fa-solid fa-chevron-left" />
            </button>
            <button className="image-array-right">
              <i className="fa-solid fa-chevron-right" />
            </button>
          </div>
          <div className="swiper hero-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="hero-image bg-cover" style={{ backgroundImage: 'url("assets/images/home/banner.jpg")'  }}>
                  <div className="overlay-shape">
                    <img src="assets/img/hero/overlay.png" alt="img" />
                  </div>
                </div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-12">
                      <div className="hero-content text-center">
                        <h4 className="text-white" data-animation="fadeInUp" data-delay="1.3s">
                          Inam Drilling Solution
                        </h4>
                        <h1 className="text-white" data-animation="fadeInUp" data-delay="1.3s">
                          <span>Tools</span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="hero-image bg-cover" style={{ backgroundImage: 'url("assets/images/home/banner2.jpg")'  }}>
                  <div className="overlay-shape">
                    <img src="assets/img/hero/overlay.png" alt="img" />
                  </div>
                </div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-12">
                      <div className="hero-content text-center">
                        <h4 className="text-white" data-animation="fadeInUp" data-delay="1.3s">
                          Inam Drilling Solution
                        </h4>
                        <h1 className="text-white" data-animation="fadeInUp" data-delay="1.3s">
                          <span>Rigs</span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

        
        </section>
      </div>
    </>
  );
}

export default Hero;
