import React from 'react';
import "./styles.css";
import { Link } from 'react-router-dom';

function MAERigs() {

  return (<>
    <div>
      {/*<< Breadcrumb Section Start >>*/}
      <div className="breadcrumb-wrapper bg-cover" style={{ backgroundImage: 'url("assets/images/Mining/mining.jpg")' }}>
        <div className="container">
          <div className="page-heading">
            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".3s">
              <li>
              <Link to="/">
                    Home
                  </Link>
              </li>
              <li>
                <i className="fas fa-chevron-right" />
              </li>
              <li>
              <Link to="/drillingrigs">
  Mining And Exploration Rigs
</Link>
              </li>
              <li>
                {/* <i className="fas fa-chevron-right" /> */}
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">
                Sunrock-DTHR-300-Skid</li> */}
            </ul>
            <h1 className="wow fadeInUp" data-wow-delay=".5s">
              Mining & Exploration Rigs</h1>
          </div>
        </div>
      </div>


      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div id="productCarousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel" data-bs-slide-to={0} className="active" />
                      <li data-bs-target="#productCarousel" data-bs-slide-to={1} />
                      <li data-bs-target="#productCarousel" data-bs-slide-to={2} />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\merigs\1.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Blast Hole Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\Sandvik-D50KS_32109-e1604605854945 (2).jpg" className="d-block w-100 img-fluid" alt="Product Image 2" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Blast Hole Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\Sandvik-D25KS-Blasthole-Drill-for-Mining_38876-e1604606104699.jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Blast Hole Rigs</p>
                        </div>
                      </div>
                    </div>
                    {/* Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 20 }}>
                <h3>Blasthole Rigs</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                  Solutions' blast hole drilling rigs are designed for optimal performance in open-pit mining and quarry operations. These rigs excel in drilling large-diameter blast holes with high precision and efficiency. Engineered to handle tough conditions, they feature robust construction and advanced automation systems to enhance productivity and durability. Whether you’re working with hard rock or softer soils, our blast hole rigs deliver the power and control needed to optimize your blasting operations </p>
                {/* Product Features Box */}
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong> BH-7000X</li>
                    <li><strong>Engine Power:</strong> 550 HP Turbo Diesel</li>
                    <li><strong>Drilling Depth:</strong>Upto 300 meters</li>

                    <li><strong>Drilling Diameter:</strong>8-20 inches</li>
                    <li><strong>Rotary speed:</strong> 0-100 RPM</li>
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">Capable of drilling large-diameter blast holes for mining operations.
                        </li>
                        <li className="mt-2">Designed for high-efficiency drilling in tough rock formations.
                        .
                        </li>
                        <li className="mt-2">Equipped with automated drilling controls for improved productivity.
                        </li>
                        <li className="mt-2">Provides real-time performance monitoring for optimized operations</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





























      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div id="productCarousel2" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel2" data-bs-slide-to={0} className="active" />
                      <li data-bs-target="#productCarousel2" data-bs-slide-to={1} />
                      <li data-bs-target="#productCarousel2" data-bs-slide-to={2} />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\merigs\KODO-PIC-02-scaled.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Exploration Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\exploration.jpg" className="d-block w-100 img-fluid" alt="Product Image 2" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Exploration Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\edm45k-d-contanerized-diamond-core-mineral-exploration-drill-rig-IMG_0244 (1).jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Exploration Rigs</p>
                        </div>
                      </div>
                    </div>
                         {/* Controls */}
                         <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel2" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel2" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 90 }}>
                <h3>Exploration Rigs</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                Inam Drilling Solutions’ exploration rigs are designed to handle the complex demands of geological surveys, mining exploration, and field drilling applications. Engineered for efficiency and reliability, these rigs deliver accurate data and core samples critical for resource evaluation. Our exploration rigs are equipped with advanced technology to perform consistently in challenging terrains and drilling depths.
                </p>
                {/* Product Features Box */}
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong> EX-4000X</li>
                    <li><strong>Engine Power:</strong> 450 HP Turbo Diesel</li>
                    <li><strong>Drilling Depth:</strong>Upto 600 meters</li>

                    <li><strong>Drilling Diameter:</strong>4-12 inches</li>
                    <li><strong>Rotary speed:</strong> 0-120 RPM</li>
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">Delivers accurate core samples for resource evaluation in exploration projects.
                        </li>
                        <li className="mt-2">Ideal for geological surveys, mining exploration, and environmental studies.

                        </li>
                        <li className="mt-2">Built to endure harsh environments for long-term use.

                        </li>
                        <li className="mt-2">Equipped with advanced drilling technology for fast, efficient operations with real-time data monitoring.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div id="productCarousel3" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel3" data-bs-slide-to={0} className="active" />
                      <li data-bs-target="#productCarousel3" data-bs-slide-to={1} />
                      <li data-bs-target="#productCarousel3" data-bs-slide-to={2} />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\merigs\exploration.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Diamond Core Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\3.jpg" className="d-block w-100 img-fluid" alt="Product Image 2" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Dianmond Core Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\drilling.jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Diamond Core Rigs</p>
                        </div>
                      </div>
                    </div>
                    {/* Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel3" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel3" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 90 }}>
                <h3>Diamond Core Drilling Rigs</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                  Inam Drilling Solutions' diamond core drilling rigs are engineered for high-precision drilling, delivering core samples from deep within the earth. Ideal for geological exploration, mining, and geotechnical applications, these rigs provide accurate and reliable core samples essential for resource evaluation. With advanced features like automated drilling and real-time data monitoring, our rigs ensure efficient and precise operations in any terrain.</p>
                {/* Product Features Box */}
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong> DCR-8000</li>
                    <li><strong>Engine Power:</strong> 350 HP Turbo Diesel</li>
                    <li><strong>Drilling Depth:</strong>Upto 1000 meters</li>

                    <li><strong>Core Diameter:</strong>50-150 mm</li>
                    <li><strong>Rotary speed:</strong> 0-80 RPM</li>
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">E
                        
                        Delivers high-precision core samples from deep within the earth

                        </li>
                        <li className="mt-2">Automated drilling systems enhance efficiency and accuracy.

                        </li>
                        <li className="mt-2">Provides real-time data monitoring for precise analysis.
                        .
                        
                        </li>
                        <li className="mt-2">Suitable for geological exploration, mining, and geotechnical investigations</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* Car Rentals Section Start */}
      <div className="single-services py-100-70">
        <div className="container">
          <div className="row align-items-center">
            {/* Image and Carousel Section */}
            <div className="col-lg-6">
              <div className="single-services-img-box">
                <div className="carousel-wrapper mt-5">
                  <div  className="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div id="productCarousel4" className="carousel slide carousel-fade" data-bs-ride="carousel">
                  
                    {/* Indicators */}
                    {/* <div id="productCarousel4" className="carousel slide carousel-fade" data-bs-ride="carousel"></div> */}
                    <ol className="carousel-indicators">
                      <li data-bs-target="#productCarousel4" data-bs-slide-to={0} className="active" />
                      <li data-bs-target="#productCarousel4" data-bs-slide-to={1} />
                      <li data-bs-target="#productCarousel4" data-bs-slide-to={2} />
                    </ol>
                    {/* Carousel Inner Slides */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img src="assets\images\merigs\reverse-circulation-vertical.jpg" className="d-block w-100 img-fluid" alt="Product Image 1" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Reverse Circulated Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\644f755701783 (1).jpg" className="d-block w-100 img-fluid" alt="Product Image 2" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Reverse Circulated Rigs</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src="assets\images\merigs\03.jpg" className="d-block w-100 img-fluid" alt="Product Image 3" style={{ objectFit: 'cover' }} />
                        <div className="carousel-caption d-none d-md-block">
                          <p>Reverse Circulated Rigs</p>
                        </div>
                      </div>
                    </div>
                    {/* Controls */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#productCarousel4" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#productCarousel4" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
            {/* Product Description Section */}
            <div className="col-lg-6">
              <div className="single-services-content-box" style={{ marginTop: 90 }}>
                <h3>Reverse Circulated(RC)Rigs</h3>
                <p style={{ fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif', marginTop: 24, fontSize: 17, color: 'rgb(64, 63, 63)' }}>
                Inam Drilling Solutions' Reverse Circulation (RC) drilling rigs are designed for rapid and effective drilling, providing high-quality samples crucial for mining and exploration projects. Renowned for their speed and cost-efficiency, RC drilling rigs are ideal for exploration applications. Our rigs feature robust construction and powerful compressors, ensuring performance in challenging environments. Additionally, we offer a comprehensive range of spare parts to maintain peak operational efficiency, minimizing downtime and s</p>
                <div className="product-features-box">
                  <h4>Product Features</h4>
                  <ul className="product-features" style={{ color: 'rgb(226, 45, 9)', fontSize: 20 }}>
                    <li><strong>Model:</strong> RC-3500X</li>
                    <li><strong>Engine Power:</strong> 400 HP Turbo Disel</li>
                    <li><strong>Drilling Depth:</strong>Upto 300 meters</li>

                    <li><strong>Drilling Diameter:</strong>4-8 inches</li>
                    <li><strong>Rotary speed:</strong> 0-100 RPM</li>
                  </ul>
                  {/* Buttons and Modal */}
                  <div className="btn-box">
                    <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#enquiryModal">Enquiry Form</a>
                    <a className="btn btn-secondary" href="#">Download Brochure</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enquiry Modal */}
      <div className="modal fade" id="enquiryModal" tabIndex={-1} aria-labelledby="enquiryModalLabel" aria-hidden="true" style={{ paddingTop: 100 }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ zIndex: 1055 }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel" style={{ marginBottom: 3 }}>Enquiry Form</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form action method="post" id="enquiryForm">
                <div className="mb-3">
                  <label htmlFor="fname" className="form-label">Full Name</label>
                  <input type="text" className="form-control" id="fname" placeholder="Enter Your Full Name" name="fname" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" placeholder="Enter Your Email" name="email" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="number" className="form-label">Phone Number</label>
                  <input type="text" className="form-control" id="number" placeholder="Enter Phone Number" name="number" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="narration" className="form-label">Message</label>
                  <textarea className="form-control" id="narration" rows={3} placeholder="Your message..." required defaultValue={""} />
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Optional Styling to Fix Backdrop Interaction */}
      <section className="blog py-50-70">
        <div className="container">
          <div className="features-style-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="item">
                  <div className="content-box" style={{ marginBottom: 16 }}>
                    <div className="about-quote mt-3">
                      <h2 className="key-features-heading">Key Features</h2>
                      <ul>
                        <li className="mt-4">Provides rapid and efficient sample collection.
                        .
                        </li>
                        <li className="mt-2">Cost-effective solution for exploration and mining
                        </li>
                        <li className="mt-2">Robust construction with powerful compressors for tough conditions
                        </li>
                        <li className="mt-2">SWide range of spare parts available for optimal performance.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>);

}
export default MAERigs;